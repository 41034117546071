import React from 'react'
import TrainingTemplate from 'components/templates/TrainingTemplate/TrainingTemplate'

export default function TrainingWorkSafetyPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Sicurezza del lavoro'

  const description = 'La formazione prevista dal D. Lgs. 81.08 è così strutturata:'

  const photo = {
    0: ['corso-formazione-generale-diagramma.jpg', 'Formazione preposti'],
    1: ['corso-formazione-preposti-diagramma.jpg', 'Formazione preposti'],
    2: ['corso-formazione-dirigenti-diagramma.jpg', 'Formazione preposti'],
  }

  const description1 =
    'Per i lavoratori, il modulo di formazione specifica ha una durata differenziata in base al settore ATECO di appartenenza dell’azienda e non costituisce un credito formativo permanente, quindi è soggetto ad aggiornamento.<br><br> Riepiloghiamo la <b>durata minima complessiva</b> dei corsi di formazione per i lavoratori, in base alla classificazione dei settori:<br> • 4 ore di Formazione Generale + 4 ore di Formazione Specifica per i settori della classe di rischio basso: TOTALE <b>8 ore</b>;<br> • 4 ore di Formazione Generale + 8 ore di Formazione Specifica per i settori della classe di rischio medio: TOTALE <b>12 ore</b>;<br> • 4 ore di Formazione Generale + 12 ore di Formazione Specifica per i settori della classe di rischio alto: TOTALE <b>16 ore</b>.'

  return (
    <>
      <TrainingTemplate
        seoKeywords={seoKeywords}
        productName={productName}
        description={description}
        photo={photo}
        description1={description1}
      />
    </>
  )
}
